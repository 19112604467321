<template>
  <div class="main-container">
    <h5 class="main-title">교육 영상 관리</h5>
    <div class="search-container">
      <h5 class="search-title">조회 옵션 선택</h5>
      <div class="search-box">
        <p class="search-sub-title">업로드 카테고리</p>
        <mdb-select
            outline
            v-model="oneOptions"
            @getValue="getSelectOneValue"
            class="search-select"/>
        <mdb-select
            v-if="oneOptionVal === '상지'"
            outline
            v-model="ueOptions"
            @getValue="getSelectUlValue"
            class="search-select"/>
        <mdb-select
            v-if="oneOptionVal === '하지'"
            outline
            v-model="leOptions"
            @getValue="getSelectUlValue"
            class="search-select"/>
      </div>
      <div class="search-box">
        <p class="search-sub-title">영상 검색</p>
        <div class="icon-input">
          <img src="@/assets/images/IA/icon_search.png" alt="search">
          <input v-model="search" placeholder="검색어를 입력하세요." class="custom-input-box search-input"/>
        </div>
        <button class="custom-btn-box search-btn" @click="dataInit">
          <img src="@/assets/images/IA/icon_play_w.png" alt="play">
          영상 검색
        </button>
      </div>
    </div>
    <div class="sub-container">
      <p>Vimeo에 업로드한 링크를 등록해주세요. </p>
      <button class="custom-btn-box add-video-btn" @click="addVideo">
        <img src="@/assets/images/IA/plus-circle.png" alt="excel">
        영상 등록
      </button>
    </div>
    <div class="table-container">
      <mdb-datatable-2
          v-model="data"
          class="text-center"
          borderless
          hover
          :searching="{value: search, field: fieldName} "
          @selected="onSelected($event)"
          noFoundMessage="데이터가 없습니다."/>
    </div>
    <div class="sub-container">
      <p v-html="videoName" class="video-name"></p>
    </div>
    <div class="result-null-container" v-if="isDataNull">
      <img src="@/assets/images/IA/img_null.png" alt="null">
      <h5>조회할 영상을 선택해주세요.</h5>
    </div>
    <VideoUploadForm :selData="selData" v-else @refresh="refresh"/>
  </div>
</template>

<script>
import {mdbDatatable2, mdbSelect} from "mdbvue";
import VideoUploadForm from "@/views/master/class/components/VideoUploadForm";
import video_columns from "@/assets/data/master/edu/video_columns";
import {firebase} from "@/firebase/firebaseConfig";

export default {
  name: "VideoUpload",
  components: {
    VideoUploadForm,
    mdbSelect,
    mdbDatatable2,
  },
  data() {
    return {
      fbCollection: 'videos',
      data: {
        columns: video_columns,
        rows: []
      },
      oneOptions: [
        {text: "전체", value: "전체", selected: true},
        {text: "상지", value: "상지"},
        {text: "하지", value: "하지"},
      ],
      ueOptions: [
        {text: "전체", value: "전체", selected: true},
        {text: "FM", value: "Fugl-Meyer"},
        {text: "BB", value: "BB"},
        {text: "WMFT", value: "WMFT"},
        {text: "AAUT", value: "AAUT"},
        {text: "MAL", value: "MAL"},
        {text: "SIS", value: "SIS"},
      ],
      leOptions: [
        {text: "전체", value: "전체", selected: true},
        {text: "FM_LOW", value: "Fugl-Meyer-Low"},
        {text: "BBS", value: "BBS"},
        {text: "TUG", value: "TUG"},
        {text: "FTSTS", value: "FTSTS"},
        {text: "TMWT", value: "TMWT"},
        {text: "SMWT", value: "SMWT"},
        {text: "DGI", value: "DGI"},
        {text: "ABCscale", value: "ABCscale"},
      ],
      oneOptionVal: '전체',
      ulOptionVal: '전체',
      search: '',
      fieldName: '',
      isDataNull: true,
      videoName: '영상 정보',
      selData: {},
    }
  },
  mounted() {
    this.dataInit();
  },
  methods: {
    dataInit() {
      const self = this;
      self.data.rows.splice(0);
      const db = firebase.firestore();
      let bsRef = db.collection(self.fbCollection);
      if (self.oneOptionVal !== '전체') bsRef = bsRef.where('ul', '==', self.oneOptionVal)
      if (self.ulOptionVal !== '전체') bsRef = bsRef.where('toolName', '==', self.ulOptionVal)

      bsRef.orderBy("orderNumber", "asc").get().then(async (querySnapshot) => {
        if (querySnapshot.size !== 0) {
          querySnapshot.forEach((doc) => {
            const _data = doc.data();
            _data["docId"] = doc.id;
            self.data.rows.push(_data);
          });
        }
      })
    },
    getSelectOneValue(value) {
      this.oneOptionVal = value;
      if (value === '전체') this.ulOptionVal = value;
    },
    getSelectUlValue(value) {
      this.ulOptionVal = value;
    },
    onSelected(event) {
      const self = this;
      if (!event) {
        return
      }
      self.isDataNull = false;
      self.selData = event

      self.detailTitle = event.title;
    },
    addVideo() {
      this.$router.push('/master/videoAddPage')
    },
    refresh() {
      const self = this;
      self.dataInit();
      self.isDataNull = true;
    },


  }
}
</script>

<style scoped>
.main-container {
  background: white;
  padding: 44px 20px;
}

.main-title {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 30px;
}

.search-container {
  width: 100%;
  height: 174px;
  background: #FFFFFF;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 23px 20px;
}

.search-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
}

.search-title {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #272833;
  margin: 0 0 12px 0;
}

.search-sub-title {
  font-style: normal;
  font-weight: normal;
  width: 112px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #272833;
  margin: 0 10px 0 0;
}

.search-btn {
  width: 140px;
  height: 40px;
  background: #0069CA;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}

.search-btn img {
  margin-right: 6px;
  width: 18px;
}

/deep/ .caret {
  display: none;
}

.search-select {
  margin-right: 10px;
}

/deep/ .search-select .md-form {
  width: 151px;
  padding: 0 !important;
  margin: 0 !important;
  background: url('../../../assets/images/IA/icon_down.png') no-repeat right 9px center;
  background-size: 18px 18px;
}

/deep/ .search-select .form-control {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  height: 40px;
  letter-spacing: 0.0025em;
  color: #999999;
  margin: 0;
}

.search-input {
  width: 100%;
  padding-left: 30px;
  background: #FFFFFF;
  font-size: 16px;
}

.icon-input {
  position: relative;
  flex: 1;
  margin-right: 10px;
}

/deep/ .icon-input ::placeholder {
  font-size: 16px !important;
}

.icon-input img {
  position: absolute;
  top: 11px;
  left: 8px;
  z-index: 1;
  width: 18px;
  height: 18px;
}

.sub-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0 6px;
}

.sub-container p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #E14F4E;
  margin: 0;
}

.video-name {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #272833 !important;
  margin: 0;
}

.add-video-btn {
  width: 140px;
  height: 40px;
  background: #24C299;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
  margin-right: 20px;
}

.add-video-btn img {
  width: 18px;
  margin-right: 15px;
  margin-left: 10px;
}

.table-container {
  width: 100%;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
}

.result-null-container {
  margin-top: 15px;
  width: 100%;
  height: 276px;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.result-null-container img {
  width: 101px;
  margin-bottom: 16px;
}

.result-null-container h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #B1B1B1;
  margin: 0;
}

/deep/ td {
  cursor: pointer;
}
</style>
