<template>
  <div class="form-container">
    <div class="info-flex">
      <div>
        <div class="bs-flex">
          <h5 class="info-title">상/하지 선택</h5>
          <input type="text" class="custom-input-box dis" v-model="selData.ul" disabled/>
          <h5 class="info-title" style="margin-left: 10px">평가도구 선택</h5>
          <input type="text" class="custom-input-box dis" v-model="selData.toolName" disabled/>
        </div>
        <div class="bs-flex">
          <h5 class="info-title">영상 순서</h5>
          <input type="number" class="custom-input-box dis" v-model="selData.orderNumber" disabled/>
        </div>
        <div class="bs-flex">
          <h5 class="info-title">영상 제목 입력</h5>
          <input type="text" class="custom-input-box dis" v-model="selData.title" disabled/>
        </div>
        <div class="bs-flex">
          <h5 class="info-title">영상 업로드 링크</h5>
          <input type="text" class="custom-input-box dis" v-model="selData.url" disabled/>
        </div>
        <div class="bs-flex">
          <h5 class="info-title">영상 재생 시간</h5>
          <input type="text" class="custom-input-box dis " v-model="selData.time" disabled/>
        </div>
      </div>
    </div>
    <hr/>
    <div class="bs-flex">
      <button class="btn-unselect" @click="deleteBoard">등록 해제</button>
    </div>
  </div>
</template>

<script>

import {firebase} from "@/firebase/firebaseConfig";

export default {
  name: "VideoUploadForm",
  components: {
  },
  props: {
    selData: Object,
  },
  data() {
    return {
      fbCollection: 'videos',
      oneOptions: [],
      threeOptions: [],
      oneValue: '',
      toolValue: '',
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const self = this
      self.oneOptions = []
      self.threeOptions = []
      const oneOptionList = ['상지', '하지'];
      const toolOptionList = ['Fugl-Meyer', 'BB', 'WMFT', 'AAUT', 'MAL', 'SIS'];

      const type = self.selData.ul
      for (let i = 0; i < oneOptionList.length; i++) {
        const demo = {}
        demo['value'] = oneOptionList[i];
        demo['text'] = oneOptionList[i];
        if (oneOptionList[i] === type) {
          demo['selected'] = true
        } else {
          demo['selected'] = false
        }
        self.oneOptions.push(demo);
      }

      const type2 = self.selData.toolName
      for (let i = 0; i < toolOptionList.length; i++) {
        const demo = {}
        demo['value'] = toolOptionList[i];
        demo['text'] = toolOptionList[i];
        if (toolOptionList[i] === type2) {
          demo['selected'] = true
        } else {
          demo['selected'] = false
        }
        self.threeOptions.push(demo);
      }
    },
    getSelectMainValue(value) {
      const self = this;
      self.oneValue = value;
      if (value === '하지') {
        self.toolValue = '전체';
      }
    },
    deleteBoard() {
      const self = this;
      const docRef = firebase.firestore().collection(self.fbCollection);
      docRef.doc(self.selData.docId).delete().then(() => {
        alert('삭제 되었습니다.')
        self.$emit('refresh', 0)
      })
    },
  }
}
</script>

<style scoped>
.form-container {
  width: 100%;
  height: 478px;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 27px 18px;
}

.bs-flex {
  display: flex;
  flex-direction: row;
}

.info-flex {
}

.info-flex .bs-flex {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

.info-title {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 40px;
  letter-spacing: 0.0015em;
  color: #272833;
  min-width: 150px;
  margin-right: 10px;
}

hr {
  position: relative;
  padding: 0 !important;
  margin-top: 12px;
  margin-bottom: 27px;
  border-top: 2px solid #E1E3E6;
}

/deep/ .caret {
  display: none;
}

.search-select {
  margin-right: 10px;
}

/deep/ .search-select .md-form {
  width: 237px !important;
  padding: 0 !important;
  margin: 0 !important;
  background: url('../../../../assets/images/IA/icon_down.png') no-repeat right 9px center;
  background-size: 18px 18px;
}

/deep/ .search-select .form-control {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  height: 40px;
  letter-spacing: 0.0025em;
  color: #999999;
  margin: 0;
}

.w654 {
  width: 654px;
}

.w237 {
  width: 237px;
}

.btn-unselect {
  margin-left: auto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #FFFFFF;
  width: 116px;
  height: 40px;
  background: #E14F4E;
  border-radius: 4px;
  border: 0;
  margin-right: 10px;
}

.dis{
  background: #F8F8FA !important;
}
</style>
