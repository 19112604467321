export default [
    {
        label: 'No.',
        field: 'orderNumber',
        sort: true
    },
    {
        label: '상/하지',
        field: 'ul',
        sort: true
    },
    {
        label: '평가도구',
        field: 'toolName',
        sort: true
    },
    {
        label: '영상구분',
        field: 'type',
        sort: false
    },
    {
        label: '영상제목',
        field: 'title',
        sort: false
    },
    {
        label: '재생시간',
        field: 'time',
        sort: false
    }
];
